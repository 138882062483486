<template>
  <main class="page-projects page-show-project p-0 d-flex flex-column">
    <ul class="nav nav-pills nav-fill">
      <li class="nav-item">
        <router-link class="nav-link active" :to="{ name: 'projects.show' }" aria-current="page">Dati</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'projects.edit' }">Modifica</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'projects.visea' }">Modulo ViSEA</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'projects.visea.graph' }">Modulo ViSEA (Grafo)</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'projects.tutorial' }">Tutorial</router-link>
      </li>
    </ul>
    <div class="flex-grow-1 container pt-3">
      <div class="table-responsive">
        <table class="table table-condensed">
          <tbody>
          <tr>
            <th>Nome</th>
            <td>{{ project.name }}</td>
          </tr>
          <tr>
            <th>Modulo visea</th>
            <td>{{ project.hasModuleVisea ? 'Attivo' : 'Non attivo' }}</td>
          </tr>
          <tr>
            <th>Note</th>
            <td>{{ project.notes }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-2 mb-5 text-right" v-if="isAdmin">
        <button type="button" @click.prevent="removeProject" class="btn btn-outline-danger">Cancella progetto</button>
      </div>

      <div class="d-flex flex-wrap justify-content-between align-items-center mt-4">
        <h2>Livelli<template v-if="projectModules.length > 0"> ({{ totalResults }})</template></h2>

        <div class="mb-3 text-right">
          <router-link class="btn btn-outline-primary" :to="{ name: 'modules.new' }">Aggiungi livello</router-link>
        </div>
      </div>

      <template v-if="projectModules && projectModules.length > 0">
        <AwlTable striped :heading="tableHeadings" :rows="tableRows">
          <template #actions-content="{ row }">
            <ActionShow :to="{ name: 'modules.show', params: { moduleId: row.id } }" />
            <ActionEdit class="ml-2" :to="{ name: 'modules.edit', params: { moduleId: row.id } }" />
          </template>
        </AwlTable>

        <div class="row justify-content-end align-items-center">
          <div class="col-auto text-right">
            <p>
              Risultati da mostrare
              <PerPageSelector class="d-inline w-auto" v-model="perPage" :options="perPageOptions" @input="fetchResults(1)" />
            </p>
          </div>
        </div>
        <AwlPagination :value="page" :totalPages="totalPages" @page="goToPage" />
      </template>
      <Alert v-else status="info">Nessun livello presente.</Alert>
    </div>
  </main>
</template>
<script>

import rolesMixin from '@/mixins/roles';
import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal';
import pageableMixin from '@/libs/Pagination/mixins/pageable';
import $api from '@/libs/OAuth2/services/api';

export default {
  name: 'project-show',
  mixins: [rolesMixin, confirmWithModalMixin, pageableMixin],
  components: {
    AwlPagination: () => import('@/libs/Pagination/components/AwlPagination'),
    PerPageSelector: () => import('@/libs/Pagination/components/PerPageSelector'),
    Alert: () => import('@/libs/Feedback/components/Alert'),
    AwlTable: () => import('@/libs/Table/components/AwlTable'),
    ActionShow: () => import('@/libs/Table/components/Actions/ActionShow'),
    ActionEdit: () => import('@/libs/Table/components/Actions/ActionEdit'),
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      projectModules: [],
    };
  },
  computed: {
    tableHeadings () {
      return [
        { key: 'name', label: 'Nome' },
      ];
    },
    tableRows () {
      return (this.projectModules || []);
    },
  },
  methods: {
    removeProject () {
      this.confirm('Cancellare il progetto e tutti i moduli collegati?', 'Sì', 'No')
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          this.$api.removeProject(this.project.id)
            .then(() => {
              this.$router.replace({ name: 'projects.list' });
            })
            .catch(this.$log.errors)
          ;
        })
      ;
    },
    fetchResults (page = this.page) {
      return $api.listModules(this.project.id, page, this.perPage, { project: this.project.id })
        .then(res => {
          this.projectModules = res?.data?.projectModules || [];
          this.setMetadata(res?.data);
        })
        .catch(() => this.$log.error)
      ;
    },
  },
  mounted () {
    this.fetchResults();
  },
};

</script>
